import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  id: "themes-note"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThemesVue = _resolveComponent("ThemesVue")!
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_IntroVue = _resolveComponent("IntroVue")!
  const _component_SidebarVue = _resolveComponent("SidebarVue")!
  const _component_NavbarVue = _resolveComponent("NavbarVue")!
  const _component_ColumnsVue = _resolveComponent("ColumnsVue")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemesVue),
    (_ctx.$mediaWidth.isBelow1366px)
      ? (_openBlock(), _createBlock(_component_MenuIcon, {
          key: 0,
          class: _normalizeClass({ open: _ctx.mobileMenuOpen }),
          onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mobileMenuOpen = !_ctx.mobileMenuOpen))
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    _createVNode(_component_IntroVue, { completed: _ctx.animationCompleted }, null, 8, ["completed"]),
    _createVNode(_Transition, { name: "mobile-sidebar" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_SidebarVue, {
          onCompleted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.animationCompleted = true))
        }, null, 512), [
          [_vShow, _ctx.mobileMenuOpen || !_ctx.$mediaWidth.isBelow1366px]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      id: "dynamic-content",
      class: _normalizeClass({
      'are-projects': _ctx.$areProjects,
      'slide-enter-from-left': _ctx.slideFromLeft,
    })
    }, [
      _createVNode(_component_NavbarVue),
      _withDirectives(_createVNode(_component_ColumnsVue, {
        sortedColumn: _ctx.sortedColumn,
        "onUpdate:sortedColumn": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sortedColumn) = $event))
      }, null, 8, ["sortedColumn"]), [
        [_vShow, !_ctx.$mediaWidth.isBelow768px || _ctx.$areProjects]
      ]),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, { name: "slide" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                class: "content",
                sortedColumn: _ctx.$areProjects ? _ctx.sortedColumn : null
              }, null, 8, ["sortedColumn"]))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ], 2),
    (_ctx.$mediaWidth.isBelow768px)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Swipe to change themes, sections, or projects! "))
      : _createCommentVNode("", true)
  ], 64))
}