<template>
  <svg
    width="800"
    height="800"
    viewBox="0 -12.5 256 256"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
  >
    <path
      d="M75.39 0a23.1 23.1 0 0 0-19.72 11.76L3.054 102.995a22.96 22.96 0 0 0 0 22.879l52.598 91.997a23.54 23.54 0 0 0 19.719 12.18h105.216a23.462 23.462 0 0 0 19.74-12.12l52.598-91.477a23.441 23.441 0 0 0 3.074-11.61c0-4.004-1.025-8.01-3.074-11.61L200.326 11.76A23.118 23.118 0 0 0 180.606 0H75.39Z"
      fill="#4285F4"
    />
    <path
      d="m236.495 155.027-36.17 62.905a23.462 23.462 0 0 1-19.739 12.12H140.51l-58.472-58.995 17.238-55.583-17.238-56.22 17.705 11.893 23.806 24.019-11.12-35.912 83.375 56.008 40.691 39.765Z"
      fill-opacity=".07"
    />
    <path
      d="m82.038 59.254 17.705 11.893 13.88 44.618-13.633 43.278-17.952 12.014 17.343-55.796-17.343-56.007Zm45.347 19.836 8.593 27.792h32.989L127.385 79.09Zm68.42 36.172-83.376 55.795 17.343-55.795-17.343-56.008 83.375 56.008Z"
      fill="#FFF"
    />
  </svg>
</template>
