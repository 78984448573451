
import { defineComponent } from "vue";
import LinksVue from "./Links.vue";

export default defineComponent({
  name: "IntroVue",
  components: { LinksVue },
  props: { completed: Boolean },
  data() {
    return {
      sentence: "Kristijan Rosandić — Software Engineer Portfolio",
    };
  },
  mounted() {
    let i = 0;
    const typewriterEl = this.$refs.typewriter as HTMLElement;
    const letterEls = typewriterEl.querySelectorAll("span");
    const interval = setInterval(() => {
      if (letterEls[i]) {
        letterEls[i].className = "visible";
        letterEls[i].setAttribute("caret", "");
        if (i++) letterEls[i - 2].removeAttribute("caret");
      } else {
        letterEls[i - 1].setAttribute("last", "");
        clearInterval(interval);
      }
    }, 25);
  },
});
