
import { SortedColumn } from "@/App.vue";
import { Project, columns as projectsColumns } from "@/scripts/projects";
import { sitemap } from "@/scripts/router";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "ColumnsVue",
  props: {
    sortedColumn: {
      type: Object as PropType<SortedColumn>,
      required: true,
    },
  },
  emits: ["update:sortedColumn"],
  data() {
    return { projectsColumns };
  },
  methods: {
    formatTitle(text: string) {
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    sort(name: keyof Project, descending?: boolean, isInitial?: boolean) {
      const isSameColumn = name == this.sortedColumn.name;
      descending ||= isSameColumn ? !this.sortedColumn.descending : true;
      this.$emit("update:sortedColumn", { name, descending, isInitial });
    },
  },
  computed: {
    aboutMeColumns(): string[] {
      const path = this.$pathEnding as keyof typeof sitemap.about;
      return this.$areProjects || !path ? [] : sitemap.about[path].titles;
    },
  },
  watch: {
    $route() {
      if (!this.$areProjects) return;
      console.log(this.$isBrowserApp);
      if (this.$isBrowserApp) this.sort("weeklyUsers", true, true);
      else this.sort("created", true, true);
    },
  },
});
