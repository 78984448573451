import { vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "pdf"
}
const _hoisted_2 = { class: "about-title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'pdf-enabled': _ctx.$pdfViewerReady })
  }, [
    (_ctx.$pdfViewerReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_Transition, { name: "reveal" }, {
            default: _withCtx(() => [
              (_ctx.frameReady)
                ? _withDirectives((_openBlock(), _createElementBlock("iframe", {
                    key: 0,
                    onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFrameLoad && _ctx.onFrameLoad(...args))),
                    ref: "iframe"
                  }, null, 544)), [
                    [_vShow, _ctx.frameLoaded]
                  ])
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_Transition, { name: "reveal" }, {
            default: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent('LoadingSVG'), { class: "abs-center" }, null, 512)), [
                [_vShow, !(_ctx.frameLoaded && _ctx.frameReady)]
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.docs, ({ title, files }) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: title }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(title), 1),
        (_ctx.$pdfViewerReady)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files, (file) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: file,
                  class: _normalizeClass({ 'file-active': _ctx.currentDoc.file == file, file: true }),
                  onClick: ($event: any) => (_ctx.currentDoc.file != file && _ctx.fileChanged(file))
                }, _toDisplayString(file), 11, _hoisted_4))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files, (file) => {
                return (_openBlock(), _createElementBlock("a", {
                  key: file,
                  href: _ctx.getDocPath({ title, file }),
                  target: "_blank"
                }, _toDisplayString(file), 9, _hoisted_6))
              }), 128))
            ]))
      ], 64))
    }), 128))
  ], 2))
}