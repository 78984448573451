<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 122.88 121.74"
    style="enable-background: new 0 0 122.88 121.74"
    xml:space="preserve"
  >
    <path
      fill="#21609b"
      class="st0"
      d="m120.03 90.36-.55 1.43c-9.37 18.25-25.98 29.45-49.67 29.94-11.07.23-21.63-2.61-28.52-5.7-14.88-6.68-27.39-19.08-34.22-32.32-9.8-18.98-11.57-45.39 8.81-65.11.31 0-2.47 4.28-2.16 4.28l-.56 1.83c-14.69 45.98 30.56 85.38 74.54 80.88 10.58-1.1 24.06-6.89 32.33-15.23zm0 0z"
    />
    <path fill="#21609b"
      class="st0"
      d="M93.41 71.35c11.07.08 22.62-5.1 29.47-12.36-5.99 15.2-23.33 24.97-46.58 21.86-19.47-2.61-38.01-22.2-39.92-41.83-1.4-14.29 3.58-22.81 11.79-33.16-2.85 5.41-3.92 9.58-4.18 12.24-3.13 30.92 24.68 53.09 49.42 53.25zm0 0z"
    />
    <path fill="#21609b"
      class="st0"
      d="M121.77 32.97c-1.72 3.95-11.62 11.4-16 12.33-17.32 3.69-28.41-4.52-34.22-13.87-.86-1.4-2.46-5.21-2.75-6.18C66.55 17.59 67.58 6 74.3 0c-2.02 5.7-2.32 12.33-1.25 16.64.65 2.6 2.61 7.15 4.58 10.01 3.58 5.21 5.85 6.47 10.46 9.24 2.07 1.24 4.3 2.24 5.26 2.52 4.36 1.28 13.57 4.66 28.42-5.44zm0 0z"
    />
  </svg>
</template>
