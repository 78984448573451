import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "root-bracket"
}
const _hoisted_2 = {
  key: 0,
  class: "key"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "bracket"
}
const _hoisted_5 = {
  key: 3,
  class: "value"
}
const _hoisted_6 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "sidebar",
    onTouchstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hovering = true)),
    onTouchend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hovering = false)),
    onMouseenter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hovering = true)),
    onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hovering = false))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lines, (line, i) => {
      return (_openBlock(), _createElementBlock("div", { key: i }, [
        _createElementVNode("div", {
          class: "number",
          style: _normalizeStyle({ animationDelay: i * _ctx.pause + 'ms' })
        }, _toDisplayString(i + 1), 5),
        _createElementVNode("div", {
          class: "line",
          style: _normalizeStyle({ animationDelay: i * _ctx.pause + 'ms' })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(line.indents * 2, (i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "dot",
              key: i
            }, "•"))
          }), 128)),
          _createElementVNode("div", null, [
            (line.indents == 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(line.value), 1))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (line.key)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, "\"" + _toDisplayString(line.key) + "\"", 1))
                    : _createCommentVNode("", true),
                  (line.key)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, ": "))
                    : _createCommentVNode("", true),
                  (line.bracket)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(line.value), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, "\"" + _toDisplayString(line.value) + "\"", 1)),
                  (!(line.noComma || (line.key && line.bracket)))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, ","))
                    : _createCommentVNode("", true)
                ], 64))
          ])
        ], 4)
      ]))
    }), 128))
  ], 32))
}