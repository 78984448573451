import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "navbar" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, {
      name: "slide",
      appear: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerLinks, (value, key) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            class: "hover",
            key: key,
            to: key
          }, {
            default: _withCtx(() => [
              (_ctx.$mediaWidth.isBelow768px && value.short)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(value.short), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(value.long.split("-")[0]) + " ", 1),
                    (_ctx.$mediaWidth.isBelow768px)
                      ? (_openBlock(), _createElementBlock("br", _hoisted_2))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(value.long.split("-")[1]), 1)
                  ], 64)),
              _createElementVNode("div", {
                style: _normalizeStyle({ transform: `translateX(${_ctx.transformX * 100}%)` }),
                class: _normalizeClass({ 'is-touch-device': _ctx.isTouchDevice })
              }, null, 6)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}