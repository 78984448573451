
import { sitemap } from "@/scripts/router";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NavbarVue",
  data() {
    return {
      transformX: 0,
      isTouchDevice:
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0,
    };
  },
  mounted() {
    const [a, b] = ["/about/experience", "/projects/desktop"];
    const contentEl = this.$el.parentElement as HTMLElement;
    let x0: number, y0: number, cancelled: boolean, isMultitouch: boolean;
    contentEl.addEventListener(
      "touchstart",
      (e) => {
        e.stopPropagation();
        x0 = e.changedTouches[0].screenX;
        y0 = e.changedTouches[0].screenY;
        isMultitouch = e.touches.length > 1;
        cancelled = false;
      },
      { passive: true }
    );
    contentEl.addEventListener(
      "touchmove",
      (e) => {
        if (cancelled || isMultitouch || window.visualViewport?.scale != 1)
          return (this.transformX = 0);
        const x1 = e.changedTouches[0].screenX;
        const y1 = e.changedTouches[0].screenY;
        const deg = (Math.atan2(y1 - y0, x1 - x0) * 180) / Math.PI;
        const isHoriz = (-30 < deg && deg < 30) || -150 > deg || deg > 150;
        if (!isHoriz) return (cancelled = !(this.transformX = 0));
        const { width } = window.visualViewport;
        const perc = ((x0 - x1) / width) * this.paths.length;
        const { 0: first, [this.paths.length - 1]: last } = this.paths;
        const left = this.$pathEnding == first && this.$areProjects ? 0 : -1;
        const right = this.$pathEnding == last && !this.$areProjects ? 0 : 1;
        this.transformX = Math.max(left, Math.min(right, perc));
      },
      { passive: true }
    );
    contentEl.addEventListener(
      "touchend",
      (e) => {
        e.stopPropagation();
        if (e.touches.length || window.visualViewport?.scale != 1) return;
        if (isMultitouch) return (isMultitouch = false);
        if (!this.transformX) return;
        if (Math.abs(this.transformX) < 0.5) return (this.transformX = 0);
        const isRight = this.transformX > 0;
        const idx = this.paths.indexOf(this.$pathEnding);
        const newPath = this.paths[idx + (isRight ? 1 : -1)];
        const timeout = newPath ? 0 : 150;
        this.$router.push(newPath || (this.$areProjects ? a : b));
        this.transformX += isRight ? -1 : 1;
        setTimeout(() => (this.transformX = 0), timeout);
      },
      { passive: true }
    );
  },
  computed: {
    paths(): string[] {
      return Object.keys(this.routerLinks);
    },
    routerLinks(): Record<string, { long: string; short?: string }> {
      return sitemap[this.$areProjects ? "projects" : "about"];
    },
  },
});
