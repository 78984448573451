
import ModalVue from "@/components/reusable/Modal.vue";
import { Project } from "@/scripts/projects";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ProjectDetails",
  components: { ModalVue },
  props: {
    modalOpen: Boolean,
    modalProject: {
      type: Object as PropType<Project>,
      required: true,
    },
    currentProjects: {
      type: Object as PropType<Project[]>,
      required: true,
    },
  },
  data() {
    return {
      x0: 0,
      y0: 0,
      isMultitouch: false,
      screenshot: {
        loaded: false,
      },
    };
  },
  methods: {
    touchStart(e: TouchEvent) {
      e.stopPropagation();
      this.isMultitouch = e.touches.length > 1;
      this.x0 = e.changedTouches[0].screenX;
      this.y0 = e.changedTouches[0].screenY;
    },
    touchMove(e: TouchEvent) {
      e.stopPropagation();
    },
    touchEnd(e: TouchEvent) {
      e.stopPropagation();
      if (e.touches.length || window.visualViewport?.scale != 1) return;
      if (this.isMultitouch) return (this.isMultitouch = false);
      const x1 = e.changedTouches[0].screenX;
      const y1 = e.changedTouches[0].screenY;
      const deg = (Math.atan2(y1 - this.y0, x1 - this.x0) * 180) / Math.PI;
      const isHoriz = (-45 < deg && deg < 45) || -135 > deg || deg > 135;
      if (isHoriz && Math.abs(x1 - this.x0) > 40)
        this.swipeProject(x1 < this.x0);
    },
    swipeProject(right: boolean) {
      const newIdx = this.projectIdx + (right ? 1 : -1);
      const lastIdx = this.currentProjects.length - 1;
      this.$emit(0 <= newIdx && newIdx <= lastIdx ? "swipe" : "close", newIdx);
    },
  },
  computed: {
    projectIdx(): number {
      return this.currentProjects.indexOf(this.modalProject);
    },
    img(): string {
      return `${this.modalProject.screenshot}.png`;
    },
  },
  directives: {
    loadedifcomplete(el, binding) {
      binding.value.loaded = el.complete;
    },
  },
});
