<template>
  <svg
    width="800"
    height="800"
    viewBox="-0.5 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#1794D4"
      d="M29 18H2V4a2 2 0 0 1 2-2h23a2 2 0 0 1 2 2v14Zm0 4a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2h27v2Zm-15 4h4v4h-4v-4ZM27 0H4a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8v4h-2a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2h-2v-4h7a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Z"
      fill-rule="evenodd"
    />
  </svg>
</template>
