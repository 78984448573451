import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "themes" }
const _hoisted_2 = {
  key: 0,
  class: "active"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$mediaWidth.isBelow768px)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.currentTheme), 1))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.themes, (theme) => {
          return (_openBlock(), _createElementBlock("div", {
            key: theme,
            onClick: ($event: any) => (_ctx.changeTheme(theme)),
            class: _normalizeClass({ active: theme == _ctx.currentTheme, hover: true })
          }, _toDisplayString(theme), 11, _hoisted_3))
        }), 128))
  ]))
}