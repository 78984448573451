
import { sitemap } from "@/scripts/router";
import { defineComponent } from "vue";
import ColumnsVue from "./components/Columns.vue";
import MenuIcon from "./components/MenuIcon.vue";
import NavbarVue from "./components/Navbar.vue";
import SidebarVue from "./components/Sidebar.vue";
import ThemesVue from "./components/Themes.vue";
import IntroVue from "./components/intro/Intro.vue";
import { Project } from "./scripts/projects";

const aboutMeKeys = Object.keys(sitemap.about);
const sortedColumn = {
  name: "created" as keyof Project,
  descending: true,
  isInitial: true,
};
export type SortedColumn = typeof sortedColumn;

export default defineComponent({
  components: {
    ThemesVue,
    MenuIcon,
    IntroVue,
    SidebarVue,
    NavbarVue,
    ColumnsVue,
  },
  data() {
    return {
      mobileMenuOpen: false,
      animationCompleted: false,
      slideFromLeft: false,
      sortedColumn,
    };
  },
  watch: {
    mobileMenuOpen(newVal) {
      if (this.$mediaWidth.isBelow768px) {
        window.scrollTo(0, 0);
        document.body.style.overflow = newVal ? "hidden" : "auto";
      }
    },
    $pathEnding(newVal: string, prevVal: string) {
      this.slideFromLeft =
        this.$areProjects ||
        aboutMeKeys.indexOf(newVal) < aboutMeKeys.indexOf(prevVal);
    },
  },
});
