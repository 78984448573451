<template>
  <svg
    fill="none"
    height="1024"
    width="1024"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#1ad1a5" height="256" rx="128" width="512" x="256" y="670.72" />
    <circle cx="512" cy="353.28" fill="#fff" r="256" />
    <circle
      cx="512"
      cy="353.28"
      r="261"
      stroke="#000"
      stroke-opacity=".2"
      stroke-width="10"
    />
    <circle cx="512" cy="353.28" fill="#ff9903" r="114.688" />
  </svg>
</template>
