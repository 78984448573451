<template>
  <svg
    width="800"
    height="800"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g data-name="Product Icons">
      <path
        class="cls-1"
        fill="#669df6"
        fill-rule="evenodd"
        d="M3.33 7 12 2l8.66 5v9.72L17.33 14H6.67l-3.34 2.67ZM16 8.67a4 4 0 1 0-4 4 4 4 0 0 0 4-4Zm-1.33 0A2.67 2.67 0 1 1 12 6a2.67 2.67 0 0 1 2.67 2.67Z"
      />
      <path
        class="cls-2"
        fill="#4285f4"
        d="M12 12.67a4 4 0 0 0 0-8V2l8.66 5v9.72L17.33 14H12Zm2.67-4A2.67 2.67 0 0 1 12 11.31V6a2.67 2.67 0 0 1 2.67 2.66Z"
      />
      <path
        class="cls-1"
        fill="#669df6"
        fill-rule="evenodd"
        d="m4 18 8 4 8-4-3.33-2.67H7.33L4 18z"
      />
      <path class="cls-2" fill="#4285f4" d="m12 22 8-4-3.33-2.67H12V22z" />
    </g>
  </svg>
</template>
