import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "columns" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "columns",
    css: _ctx.$pdfViewerReady
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_TransitionGroup, { name: "slide" }, {
          default: _withCtx(() => [
            (_ctx.$areProjects)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.projectsColumns, (column, i) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: _normalizeClass({ hover: !column.unsortable, first: i == 0 }),
                    onClick: ($event: any) => (!column.unsortable && _ctx.sort(column.name))
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.formatTitle(column.name)) + " ", 1),
                    _createVNode(_Transition, { name: "sort-icon" }, {
                      default: _withCtx(() => [
                        (_ctx.sortedColumn.name == column.name)
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent('ArrowDownSVG'), {
                              key: 0,
                              style: _normalizeStyle({
                  transform: `scale(${_ctx.sortedColumn.descending ? 1 : -1})`,
                })
                            }, null, 8, ["style"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ], 10, _hoisted_2)), [
                    [_vShow, !column.browserOnly || _ctx.$isBrowserApp]
                  ])
                }), 128))
              : (_ctx.$pathEnding != 'documents' || !_ctx.$pdfViewerReady)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.aboutMeColumns, (columnName) => {
                    return (_openBlock(), _createElementBlock("div", { key: columnName }, _toDisplayString(columnName), 1))
                  }), 128))
                : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, _ctx.$pathEnding != 'documents' || !_ctx.$pdfViewerReady]
      ])
    ]),
    _: 1
  }, 8, ["css"]))
}