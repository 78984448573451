<template>
  <svg
    width="800"
    height="800"
    viewBox="0 0 44 44"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 37.714a6.286 6.286 0 1 1 12.572 0 6.286 6.286 0 0 1-12.572 0ZM44 44h-8.38C35.62 24.359 19.642 8.38 0 8.38V0c24.261 0 44 19.739 44 44Zm-14.666 0h-8.38c0-11.553-9.401-20.952-20.954-20.952v-8.382c16.175 0 29.334 13.16 29.334 29.334Z"
      fill="#FF9A00"
      fill-rule="evenodd"
    />
  </svg>
</template>
