<template>
  <svg viewBox="14.129 23.164 121.271 106.715">
    <path
      d="M133.385 125.761c.675 1.343 1.165 2.35 2.016 4.098-6.176 0-11.747.084-17.309-.084-.911-.027-2.147-.96-2.627-1.815-10.515-18.693-20.944-37.435-31.961-57.193 5.345 0 9.88.064 14.413-.012 13.92-.232 20.094-7.851 17.434-21.44-1.205-6.154-5.392-10.38-12.41-11.172-6.264-.707-12.643-.678-18.955-.476-1.603.052-3.474 1.72-4.665 3.119-14.357 16.874-28.683 33.777-42.827 50.83-2.767 3.336-4.642 4.608-9.039 4.665-4.294.056-8.648.065-13.01.065 1.354-1.727 2.174-2.723 2.918-3.643 17.908-22.152 35.794-44.324 53.863-66.345 1.329-1.62 4.001-2.983 6.066-3.008 9.641-.116 19.409-.603 28.906.695 15.265 2.085 24.403 12.015 25.944 26.185 1.596 14.672-5.175 25.365-19.943 31.503-.874.363-1.731.77-3.083 1.375 8.156 14.331 16.12 28.324 24.269 42.653Z"
    />
    <path
      d="M77.596 96.397c6.484 11.061 12.79 21.802 19.633 33.462-6.453 0-12.135.084-17.808-.087-.893-.027-2.078-1.025-2.575-1.89C68.812 113.89 60.83 99.87 52.985 85.773c-.552-.992-.458-3.014.21-3.895 2.795-3.69 5.924-7.127 9.243-11.022a29382.99 29382.99 0 0 1 15.158 25.54zM14.13 63V23.457h15.884c.088 1.681.252 3.42.26 5.16.048 10.982.23 21.969-.036 32.944-.062 2.563-1.166 5.456-2.677 7.561-3.863 5.383-8.225 10.408-13.431 16.872V63z"
    />
  </svg>
</template>
