<template>
  <svg
    width="800"
    height="800"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform="scale(1.2)"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.591 3.884A6.969 6.969 0 0 1 16 3c1.237 0 2.4.321 3.409.884l.737-.738a.5.5 0 0 1 .708.708l-.593.592A6.988 6.988 0 0 1 23 10H9a6.988 6.988 0 0 1 2.739-5.554l-.593-.592a.5.5 0 0 1 .708-.708l.738.738ZM14 7a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm5 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      fill="#87C527"
    />
    <path
      d="M5 12.5a1.5 1.5 0 0 1 3 0v6a1.5 1.5 0 0 1-3 0v-6ZM12 24v3.5a1.5 1.5 0 0 0 3 0V24h2v3.5a1.5 1.5 0 0 0 3 0V24h1a2 2 0 0 0 2-2V11H9v11a2 2 0 0 0 2 2h1Zm12-11.5a1.5 1.5 0 0 1 3 0v6a1.5 1.5 0 0 1-3 0v-6Z"
      fill="#87C527"
    />
  </svg>
</template>
