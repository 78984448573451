
import { SortedColumn } from "@/App.vue";
import ProjectDetails from "@/components/ProjectDetails.vue";
import { Project, Projects, projects } from "@/scripts/projects";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "ProjectsVue",
  components: { ProjectDetails },
  props: {
    sortedColumn: {
      type: Object as PropType<SortedColumn>,
      required: true,
    },
  },
  data() {
    return {
      projects,
      rowsEnteringDirection: "left" as "left" | "right" | false,
      modalOpen: false,
      modalProject: {} as Project,
    };
  },
  methods: {
    formatCount(n: number) {
      return n.toLocaleString() + (n % 1000 == 0 ? "+" : "");
    },
    getBrowserAppInfo(app: Project) {
      if (!app.links.chrome || app.updated) return;
      const id = app.links.chrome.includes("smoothzoom") // CWS BUG
        ? app.links.chrome
        : app.links.chrome.slice(app.links.chrome.lastIndexOf("/") + 1);

      return fetch("https://get-cws-item.kristijanros.workers.dev/" + id)
        .then((response) => response.json())
        .then((result) => {
          app.weeklyUsers = parseInt(result.weeklyUsers);
          app.updated = new Date(result.lastUpdated);
        });
    },
    sort(col: SortedColumn) {
      if (!col.isInitial) this.rowsEnteringDirection = false;
      const name = col.name as keyof Project;
      console.log(this.currentProjects, this.currentProjects.length, name);

      this.currentProjects.sort((a, b) => {
        if (name == "name") {
          if (col.descending) return a[name].localeCompare(b[name]);
          return b[name].localeCompare(a[name]);
        }
        console.log(11);

        return col.descending
          ? (b[name]! as number) - (a[name]! as number)
          : (a[name]! as number) - (b[name]! as number);
      });
    },
  },
  computed: {
    currentProjects(): Project[] {
      return this.projects[this.$pathEnding as keyof Projects];
    },
  },
  watch: {
    $pathEnding: {
      handler(newVal: string, prevVal: string) {
        const keys = Object.keys(this.projects);
        const [i1, i2] = [keys.indexOf(newVal), keys.indexOf(prevVal)];
        this.rowsEnteringDirection = i1 < i2 || i2 == -1 ? "left" : "right";
        if (this.$isBrowserApp) {
          Promise.all(this.currentProjects.map(this.getBrowserAppInfo)).then(
            () =>
              setTimeout(
                () => this.sort({ ...this.sortedColumn, isInitial: false }),
                500
              )
          );
        }
      },
      immediate: true,
    },
    sortedColumn: {
      handler(newVal: SortedColumn) {
        this.sort(newVal);
      },
    },
  },
});
