
import lines from "@/scripts/sidebar-converter";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SidebarVue",
  emits: ["completed"],
  data() {
    return {
      lines,
      pause: 50,
      pause2: 400,
      hovering: false,
    };
  },
  mounted() {
    if (this.$mediaWidth.isBelow1366px) this.animationCompleted();
    else setTimeout(this.animationCompleted, lines.length * this.pause);
  },
  methods: {
    animationCompleted() {
      this.$emit("completed");
    },
    wordEmphasis(word: HTMLElement) {
      if (!this.hovering) return;
      word.style.textShadow = "none";
      word.offsetWidth; // nosonar
      if (document.body.classList.contains("light"))
        word.style.textShadow = "0 0 1rem currentcolor";
      else
        word.style.textShadow =
          "0 0 0.6rem currentcolor, 0 0 0.4rem currentcolor";
      setTimeout(() => {
        word.style.textShadow = "none";
        setTimeout(() => this.wordEmphasis(word), this.getRandPause());
      }, this.pause2);
    },
    getRandPause() {
      return Math.random() * 3 * this.pause2;
    },
  },
  watch: {
    hovering(newVal) {
      if (!newVal) return;
      const words = document.querySelectorAll(".key, .value");
      for (const word of words as unknown as HTMLElement[]) {
        setTimeout(() => this.wordEmphasis(word), this.getRandPause());
      }
    },
  },
});
