<template>
  <svg viewBox="0 0 512 512">
    <path d="M256 140h228a256 256 0 0 1-240 371.7" fill="#fc4" />
    <path d="M357 314 244 511.7A256 256 0 0 1 40 118" fill="#0f9d58" />
    <path d="M256 140h228a256 256 1 0 0-444-22l115 196" fill="#db4437" />
    <circle
      cx="256"
      cy="256"
      r="105"
      fill="#4285f4"
      stroke="#f1f1f1"
      stroke-width="24"
    />
  </svg>
</template>
